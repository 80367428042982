<template>
  <el-row>
   <div class="bor">
       <el-input
       v-model="inurl"
      
      placeholder="请输入需转换的网页链接"
    ></el-input>

   

    <!-- <el-divider></el-divider> -->
    <el-col class="colcom">
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button>
      </el-col>
     </div>
  </el-row>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "about_pwd",
  data() {
    return {
      inurl: "",
      
     
    };
  },
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      if (this.inurl == "") {
        this.$message({
          type: "error",
          message: "不能为空",
        });
      } else {
        let api = new apiService();
        api.html_converter(
          this,
          this.work_type,
          this.inurl
        );
      }
    },
  },
  computed:{
    placeholder(){
      return this.title[this.work_type]
    }
  }
};
</script>

<style scoped>
.el-input{
  width: 500px;
  margin-top:100px;
}
.bor{
  width: 800px;
  height: 500px;
  border:  1px dashed #7083ff;
  margin: 0 auto;
  background: linear-gradient(
180deg
,#f9fbff,#f2f7fe);
border-radius: 5px;
margin-top: 50px;
}
.colcom{
  margin-top:50px;
}
</style>