<template>
  <el-main>
    <el-row class="body_img"></el-row>
    <el-row type="flex" justify="center">
      <el-col :span="17" class="bkg">
        <h1 class="big_title_fun">
          <img
            :src="url"
            style="margin-right: 10px; width: 40px"
            draggable="false"
          />{{ title }}
        </h1>
        <span style="color: #858690">{{ des }}</span>
        <html_converter :work_type="work_type"></html_converter>
      </el-col>
    </el-row>

    <el-divider></el-divider>
    <div class="si">
    <div class="b" v-if="
this.work_type == 'html_to_pdf'"><h3 style="border-left:5px solid #409EFF">Html转PDF功能的介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把Html转换成PDF
</h3>
      <p class="func_desc">复制网页链接到输入框中，网页转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量Html转换PDF模式
</h3>
      <p class="func_desc">优化转换Html转化PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>


<div class="b" v-if="
this.work_type == 'html_to_img'"><h3 style="border-left:5px solid #409EFF">Html转图片功能的介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把Html转换成图片
</h3>
      <p class="func_desc">复制网页链接到输入框中，网页转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>

<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量Html转换图片模式
</h3>
      <p class="func_desc">优化转换Html转化图片乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>
</div>

  </el-main>

  
</template>

<script>
import common from "./common";
import { Loading } from "element-ui";
import apiService from "@/api/service.js";
import html_converter from "@/views/mybody/upload_done/work_type/html_converter.vue"
export default {
  name: "html",
  props: ["work_type"],
  components:{
    html_converter
  },
  mixins: [common],
  data() {
    return {
      loading_1: "",
      appkey: {
        appKey: "wxncvt90988",
      },
    };
  }
};
</script>

<style scoped>
.el-main {
  background: #f1f3fe;
  color: #333;
  margin-top: 72px;
}

.body_img {
  height: 130px;
  background: url("~@/assets/content.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

.bkg {
  color: #5a5b64;
  text-align: center;
  margin-top: -4px;
  height: 800px;
  background: white;
  border-radius: 20px;
}

.big_title_fun {
  font-size: 32px;
}

.lu {
  height: 300px;
  width: 300px;
  /* border: 1px solid red; */
  float: left;
  border-radius: 20px;
  text-align: center;
}

.si {
  width: 1000px;
  /* border: 1px solid red; */
  border-radius: 20px;
  /* text-align: center; */
  margin: 0 auto;
}

.b {
  width: 90%;
  /* border: 1px solid red; */
  /* margin-left: 100px; */
}
/* 文字说明 */
.func_list {
  margin: 22px -138px 0 0;
}
ul {
  list-style: none;
}

.g_ul > li {
  float: left;
}

.func_item {
  width: 238px;
  height: 235px;
  margin: 34px 138px 0 0;
  text-align: center;
  padding-left: 10px;
}

.func_list .img {
  height: 80px;
}

.func_title {
  margin-top: 15px;
  font-size: 18px;
  line-height: 30px;
  color: #333;
}
.func_desc {
  margin-top: 7px;
  font-size: 14px;
  line-height: 24px;
  color: #656565;
}
</style>